const React = require('react');
const T = require('prop-types');

const { default: Styled } = require('styled-components');

const { default: Tabs } = require('@mui/material/Tabs');
const { default: Tab } = require('@mui/material/Tab');
const { default: DiscoverIcon } = require('@mui/icons-material/PersonPin');
const { default: ClassesIcon } = require('@mui/icons-material/Groups');
const { default: ConnectionsIcon } = require('@mui/icons-material/Forum');

const { default: AppBar } = require('@mui/material/AppBar');
const { default: Toolbar } = require('@mui/material/Toolbar');

const { default: MenuIcon } = require('@mui/icons-material/Menu');
const { NavLink: Link } = require('react-router-dom');

const { default: Paper } = require('@mui/material/Paper');
const { default: Avatar } = require('@mui/material/Avatar');
const { getSizedImageUrl } = require('utils/image');

const AppMenu = require('components/AppMenu');

const Snackbar = require('containers/Snackbar');
const OfflineIndicator = require('components/OfflineIndicator');
const Loader = require('components/Loader');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const UnreadDMsBadge = require('../../routes/app/containers/UnreadDMsBadge');
const UnreadClassMessagesBadge = require('../../routes/app/containers/UnreadClassMessagesBadge');
const NotificationsDialog = require('../../routes/app/containers/NotificationsDialog');
const NotificationsButton = require('../../routes/app/containers/NotificationsButton');

const { default: Classes } = require('./styles.scss');
const Colors = require('styles/colors.json');
const Fonts = require('styles/fonts.json');
const Logomark = require('static/nearpeer_n_white.svg');
const { analyticsTemplates } = require('utils/analytics');

const { Prompt } = require('react-router-dom');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: Button } = require('@mui/material/Button');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: HomeIcon } = require('@mui/icons-material/Home');
const { ELEMENT_IDS } = require('utils/constants');
const { useAuth0 } = require('hooks/useAuth0');

const { createRef } = React;

const isCordovaApp = !!window.cordova;

const InAppBrowser = isCordovaApp ? window.cordova.InAppBrowser : null;

const internals = {};

module.exports = class AppLayout extends React.Component {

    static propTypes = {
        notificationsOpen: T.bool,
        openNotifications: T.func,
        closeNotifications: T.func,
        openLogoutDialog: T.func,
        closeLogoutDialog: T.func,
        logoutDialogOpen: T.bool,
        modalOpen: T.bool,
        currentUser: T.object,
        schoolSlug: T.string,
        hasResourcesPage: T.bool,
        resourcesUrl: T.string,
        numUnreadNotifications: T.number,
        onLogout: T.func.isRequired,
        children: T.node,
        isOffline: T.bool,
        navigate: T.func.isRequired,
        rolePermissions: T.shape({
            id: T.number,
            roleId: T.number,
            schoolId: T.number,
            homeText: T.string,
            canEditAge: T.bool,
            canEditMajor: T.bool,
            canEditHomeTown: T.bool,
            canEditCareerAspiration: T.bool,
            canEditProfession: T.bool,
            canEditDepartment: T.bool,
            canEditBadge: T.bool,
            canEditTitle: T.bool,
            canEditIncomingClass: T.bool,
            canEditGraduatingClass: T.bool,
            canEditBio: T.bool,
            canEditLiveOnCampus: T.bool,
            canEditTransfer: T.bool,
            canEditVeteran: T.bool,
            canEditOnline: T.bool,
            canEditParent: T.bool,
            canEditOpenSocial: T.bool,
            canEditTimeStatus: T.bool,
            canEditStudentName: T.bool,
            canEditStudentBio: T.bool,
            canViewMajor: T.bool,
            canViewHomeTown: T.bool,
            canViewCareerAspiration: T.bool,
            canViewProfession: T.bool,
            canViewDepartment: T.bool,
            canViewBadge: T.bool,
            canViewTitle: T.bool,
            canViewIncomingClass: T.bool,
            canViewGraduatingClass: T.bool,
            canViewBio: T.bool,
            canViewLiveOnCampus: T.bool,
            canViewTransfer: T.bool,
            canViewVeteran: T.bool,
            canViewOnline: T.bool,
            canViewParent: T.bool,
            canViewOpenSocial: T.bool,
            canViewTimeStatus: T.bool,
            canViewStudentName: T.bool,
            canViewStudentBio: T.bool,
            canNotify: T.bool,
            canBatchNotify: T.bool,
            canPreapprove: T.bool,
            canCreateSurveys: T.bool,
            canDisable: T.bool,
            canCreateGroups: T.bool,
            canEditGroups: T.bool,
            canManageGroups: T.bool,
            canPlaceInModeration: T.bool
        }),
        role: T.shape({
            id: T.number,
            name: T.string,
            label: T.string
        }),
        location: T.shape({
            pathname: T.string.isRequired
        }),
        rootLoading: T.bool,
        isSSOSchool: T.bool,
        path: T.string
    }

    static getScrollContainer = () => document.getElementById(ELEMENT_IDS.scrollContainer)

    constructor() {

        super();

        this.state = {
            mobileWidth: null,
            menuOpen: false,
            displayStatus: false
        };

        this.mql = window.matchMedia('screen and (max-width: 600px)');

        this.onMenuRequestChange = this._onMenuRequestChange.bind(this);
        this.toggleMenuOff = this._toggleMenuOff.bind(this);
        this.logout = this._logout.bind(this);
        this.touchNavigate = this._touchNavigate.bind(this);
        this.openNotifications = this._openNotifications.bind(this);
        this.closeNotifications = this._closeNotifications.bind(this);
        this.handleMediaQuery = this._handleMediaQuery.bind(this);
        this.hitGaAndMaybeRun = this._hitGaAndMaybeRun.bind(this);

        this.logoutContentsRef = createRef();
    }

    UNSAFE_componentWillMount() {

        this.handleMediaQuery(this.mql);
        this.mql.addListener(this.handleMediaQuery);
    }

    componentWillUnmount() {

        this.mql.removeListener(this.handleMediaQuery);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.numUnreadNotifications > 0 && nextProps.numUnreadNotifications !== this.props.numUnreadNotifications) {
            this.setState({ displayStatus: true });
        }
    }

    _onMenuRequestChange(open, reason) {

        if (open) {
            this.setState({ menuOpen: true }, () => {

                analyticsTemplates.navigation('viewed menu', 'main: viewed menu');
            });
        }
        else {
            this.setState({ menuOpen: false });
        }
    }

    _toggleMenuOff() {

        const { menuOpen } = this.state;

        if (menuOpen) {
            this.setState({ menuOpen: false });
        }
    }

    _handleMediaQuery(mql) {

        this.setState({ mobileWidth: !!mql.matches });
    }

    _logout() {

        this.props.openLogoutDialog();

        setTimeout(() => {

            this.logoutContentsRef.current?.focus();
        }, 100);
    }

    _touchNavigate(touchEvent, path) {

        // prevent touch event from bubbling to elements behind it in ios cordova
        touchEvent.preventDefault();
        touchEvent.stopPropagation();

        setTimeout(() => this.props.navigate(path));
    }

    _openNotifications() {

        this.setState({ displayStatus: false });

        analyticsTemplates.navigationWithVal(
            'viewed notifications',
            'main: viewed notifications',
            this.props.numUnreadNotifications
        );

        this.props.openNotifications();
    }

    _closeNotifications() {

        this.props.closeNotifications();
    }

    _hitGaAndMaybeRun(gaMsg, maybeFunc) {

        analyticsTemplates.buttons('click main menu item', gaMsg);

        // Call if exists
        maybeFunc?.();
    }

    getAdminConsoleSubMenuItems = () => {

        const {
            rolePermissions,
            currentUser
        } = this.props;

        const subMenuArr = [];

        if (rolePermissions) {
            const isSuffolkSuper = currentUser?.roleId === 1 && currentUser?.schoolId === 89;

            if (!isCordovaApp && (currentUser?.email?.includes('nearpeer.net') || isSuffolkSuper)) {
                subMenuArr.push({
                    name: 'Dashboard',
                    link: '/app/admin/dashboard',
                    onClick: () => {

                        return this.hitGaAndMaybeRun(
                            'main: click main menu admin dashboard'
                        );
                    }
                });
            }

            if (rolePermissions.canBatchNotify) {
                subMenuArr.push({
                    name: 'Send Notification',
                    link: '/app/admin/batch-notify-users',
                    onClick: () => {

                        return this.hitGaAndMaybeRun(
                            'main: click main menu send notification'
                        );
                    }
                });
            }

            if (rolePermissions.canPreapprove) {
                subMenuArr.push({
                    name: 'Preapprove Users',
                    link: '/app/admin/preapprove-users',
                    onClick: () => {

                        return this.hitGaAndMaybeRun(
                            'main: click main menu preapprove users'
                        );
                    }
                });
            }

            if (rolePermissions.canCreateSurveys) {
                subMenuArr.push({
                    name: 'Survey Management',
                    link: '/app/surveys/management',
                    onClick: () => {

                        return this.hitGaAndMaybeRun(
                            'main: click main menu manage surveys'
                        );
                    }
                });
            }

            return subMenuArr;
        }

        return [];
    }

    closeLogoutDialog = () => {

        this.props.closeLogoutDialog();
        AnimatedFocusIndicator.onBlurHandler();
    }

    render() {

        const {
            currentUser,
            notificationsOpen,
            modalOpen,
            numUnreadNotifications,
            location,
            navigate,
            logoutDialogOpen,
            rolePermissions,
            schoolSlug,
            hasResourcesPage,
            resourcesUrl,
            rootLoading,
            isSSOSchool,
            path
        } = this.props;

        const { displayStatus } = this.state;

        const {
            Wrapper,
            LoaderWrapper,
            styles,
            MaxWidth,
            TitleArea,
            LogoutConfirmButton,
            // Greeting,
            HomeButton,
            ProfileButton,
            WhiteMenuIcon,
            FooterTabs
        } = internals;

        if (rootLoading || !currentUser) {
            return <LoaderWrapper>
                <Loader />
            </LoaderWrapper>;
        }

        const viewProfileLink = `/app/profile/${currentUser ? currentUser.id : ''}`;

        const isEditProfile = (path.indexOf('/app/profile/edit') === 0) || (path.indexOf('/signup/user-info') === 0);

        const filterOnDisplayCondition = (items) => {

            return items
                .filter(({ displayCondition }) => {

                    if (typeof displayCondition === 'undefined') {
                        return true;
                    }

                    return !!displayCondition;
                })
                .map(({ displayCondition, ...rest }) => rest); // Cleanup
        };

        const firstLastName = currentUser ? currentUser.firstName + ' ' + currentUser.lastName : '';

        return (
            <Wrapper onFocus={AnimatedFocusIndicator.onFocusHandler} onBlur={AnimatedFocusIndicator.onBlurHandler}>
                <React.Fragment>
                    <Prompt when={logoutDialogOpen} message='Are you sure you want to log out?' />
                    <Dialog
                        open={logoutDialogOpen}
                        onClose={this.closeLogoutDialog}
                        aria-labelledby='alert-logout-dialog-title'
                        aria-describedby='alert-logout-dialog-description'
                    >
                        <div
                            ref={this.logoutContentsRef}
                            tabIndex={0}
                            data-focus-outline='radius:15'
                        >
                            <DialogTitle id='alert-logout-dialog-title'>{'Log Out'}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id='alert-logout-dialog-description'>
                                    Are you sure you want to log out?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <div style={{ paddingBottom: 4, paddingRight: 4 }}>
                                    <Button
                                        onClick={this.closeLogoutDialog}
                                        variant='contained'
                                        color='secondary'
                                        data-focus-outline='radius:40,padding:3'
                                        style={{ marginRight: '10px' }}
                                    >
                                        No
                                    </Button>
                                    <LogoutConfirmButton
                                        onClick={() => {

                                            this.closeLogoutDialog();
                                            this.props.onLogout();
                                        }}
                                    />
                                </div>
                            </DialogActions>
                        </div>
                    </Dialog>
                </React.Fragment>

                <div className={`${Classes.fixedCaps} ${Classes.header}`}>
                    <OfflineIndicator isOffline={!!this.props.isOffline} />
                    <Paper className={Classes.paper}>
                        <div className='contentWrapper'>
                            <AppBar elevation={0}>
                                <Toolbar style={{ height: 56 }}>
                                    <MaxWidth>
                                        <AppMenu
                                            id='app-menu'
                                            Icon={<WhiteMenuIcon />}
                                            push={navigate}
                                            items={filterOnDisplayCondition([
                                                {
                                                    displayCondition: currentUser && rolePermissions && (rolePermissions.canBatchNotify || rolePermissions.canPreapprove),
                                                    name: 'Admin Console',
                                                    items: this.getAdminConsoleSubMenuItems()
                                                },
                                                {
                                                    name: 'View My Profile',
                                                    link: viewProfileLink
                                                },
                                                {
                                                    name: 'Edit My Profile',
                                                    link: '/app/profile/edit'
                                                },{
                                                    name: 'Edit My Interests',
                                                    link: '/app/profile/edit/interests'
                                                },
                                                {
                                                    name: 'User Preferences',
                                                    link: '/app/preferences'
                                                },
                                                {
                                                    name: 'My Surveys',
                                                    link: '/app/surveys/my-surveys'
                                                },
                                                {
                                                    displayCondition: !!schoolSlug && !!hasResourcesPage,
                                                    name: 'My Resources',
                                                    onClick: () => {

                                                        const url = resourcesUrl ? resourcesUrl : `https://www.nearpeer.com/resources/${schoolSlug}`;

                                                        analyticsTemplates.navigationWithVal(
                                                            'viewed resources page',
                                                            'main: viewed resources page',
                                                            url
                                                        );

                                                        if (isCordovaApp && InAppBrowser) {

                                                            InAppBrowser.open(url, '_system');
                                                        }
                                                        else {
                                                            window.open(url, '_blank');
                                                        }
                                                    }
                                                },
                                                {
                                                    name: 'Support',
                                                    link: '/app/bug-report'
                                                },
                                                {
                                                    displayCondition: !isSSOSchool,
                                                    name: 'Change Password',
                                                    link: '/app/profile/change-password'
                                                },
                                                {
                                                    name: 'Log Out',
                                                    onClick: this.logout
                                                }
                                            ])}
                                        />

                                        {currentUser && <TitleArea>
                                            <ProfileButton
                                                onClick={() => {

                                                    navigate(`/app/profile/${currentUser.id}`);
                                                }}
                                                aria-label='Profile Picture'
                                            >
                                                <div className={Classes.avatarLink}>
                                                    <Avatar
                                                        className={Classes.avatarImg}
                                                        src={getSizedImageUrl(currentUser.croppedPicture, 100)}
                                                        alt={firstLastName}
                                                        size={40}
                                                    />
                                                </div>
                                            </ProfileButton>
                                            <div className={Classes.logoContainer}>
                                                <img src={Logomark} alt='Nearpeer logo' className={Classes.logomark} />
                                                <h1
                                                    id='main-page-heading'
                                                    tabIndex={0}
                                                >
                                                    Nearpeer
                                                </h1>
                                            </div>
                                            <HomeButton aria-label='navigate to home' onClick={() => navigate('/app/welcome')}>
                                                <HomeIcon style={{ color: '#ffffff' }} />
                                            </HomeButton>
                                        </TitleArea>}

                                        {!modalOpen &&
                                            <NotificationsButton
                                                onClick={this.openNotifications}
                                                style={{
                                                    color: Colors.lightText
                                                }}
                                            />
                                        }
                                    </MaxWidth>
                                </Toolbar>
                            </AppBar>
                        </div>
                    </Paper>
                </div>
                {/*
                    The 'notificationsOpen' switch is for a11y —
                    we don't want anything to be tabbable behind a modal that's open
                */}
                {notificationsOpen && <div className={isEditProfile ? `${Classes.main} ${Classes.editProfile}` : `${Classes.main}`} />}
                {!notificationsOpen && <div className={isEditProfile ? `${Classes.main} ${Classes.editProfile}` : `${Classes.main}`}>

                    {/* Portal for scroll sibling top */}
                    <div
                        id={ELEMENT_IDS.scrollPortalIds.top}
                        className={`contentWrapper ${Classes.scrollPortalTop}`}
                    />

                    {/* Scroll container */}

                    <div id={ELEMENT_IDS.scrollContainer} className={`${Classes.scrollContainer} ${!modalOpen && Classes.smoothScrolling}`}>

                        {/* Portal for scroll padding top */}
                        <div
                            id={ELEMENT_IDS.scrollPortalPaddingIds.top}
                            style={styles.scrollPadding}
                            className='contentWrapper'
                        />

                        <div className={`${Classes.mainInnerWrapper} contentWrapper`}>
                            {this.props.children}
                        </div>

                        {/* Portal for scroll padding bottom */}
                        <div
                            id={ELEMENT_IDS.scrollPortalPaddingIds.bottom}
                            style={styles.scrollPadding}
                            className='contentWrapper'
                        />
                    </div>

                    {/* Portal for scroll sibling bottom */}
                    <div
                        id={ELEMENT_IDS.scrollPortalIds.bottom}
                        className={`contentWrapper ${Classes.scrollPortalBottom}`}
                    />
                </div>}
                {!modalOpen && <div className={`${Classes.fixedCaps} ${Classes.footer}`}>
                    <Paper className={Classes.paper} elevation={4} id={ELEMENT_IDS.appBottomTabs}>
                        <div className={`${Classes.tabsWrapper} contentWrapper`} role='navigation' aria-label='main'>
                            <FooterTabs location={location} />
                        </div>
                    </Paper>
                </div>}
                <NotificationsDialog
                    open={notificationsOpen}
                    onRequestClose={this.closeNotifications}
                />
                {displayStatus && <div aria-live='assertive' style={{ visibility: 'hidden',height: 0 }}>
                    {numUnreadNotifications} new notification(s).
                </div>}
                <Snackbar style={styles.snackbar} />
                <AnimatedFocusIndicator />
            </Wrapper>
        );
    }
};

internals.LogoutConfirmButton = function LogoutConfirmButton(props) {

    const { onClick } = props;

    const { logout } = useAuth0();

    return (
        <Button
            variant='contained'
            onClick={() => {

                logout();
                onClick();
            }}
            data-focus-outline='radius:40,padding:3'
        >
            Yes
        </Button>
    );
};

internals.LogoutConfirmButton.propTypes = {
    onClick: T.func.isRequired
};

internals.FooterTabs = function FooterTabs(props) {

    const { location: { pathname: path } } = props;

    const { TabLabel, NullTab } = internals;

    const isDiscover = path.startsWith('/app/discover');
    const isMessaging = path.startsWith('/app/messaging');
    const isClasses = path.startsWith('/app/classes');

    // If we're not on any of these paths, deselect all tabs
    const tab = isDiscover ? 'discover'
        : isMessaging ? 'messaging'
            : isClasses ? 'classes' : 'null';

    return (
        <Tabs
            value={tab}
            variant='fullWidth'
        >
            <Tab
                LinkComponent={Link}
                to={'/app/discover'}
                value='discover'
                tabIndex={0}
                icon={<DiscoverIcon sx={{ fontSize: 28 }} />}
                label=""
                sx={{ minHeight: 'unset', padding: '8px 0' }}
                data-focus-outline='inside,radius:5'
            />
            <Tab
                LinkComponent={Link}
                to={'/app/messaging'}
                value='messaging'
                tabIndex={0}
                icon={<UnreadDMsBadge><ConnectionsIcon sx={{ fontSize: 28 }} /></UnreadDMsBadge>}
                label=""
                sx={{ minHeight: 'unset', padding: '8px 0' }}
                data-focus-outline='inside,radius:5'
            />
            <Tab
                LinkComponent={Link}
                to={'/app/classes'}
                value='classes'
                tabIndex={0}
                icon={<UnreadClassMessagesBadge><ClassesIcon sx={{ fontSize: 28 }} /></UnreadClassMessagesBadge>}
                label=""
                sx={{ minHeight: 'unset', padding: '8px 0' }}
                data-focus-outline='inside,radius:5'
            />
            <NullTab value='null' label={'Null tab'} />
        </Tabs>
    );
};

internals.FooterTabs.propTypes = {
    location: T.shape({
        pathname: T.string.isRequired
    })
};

internals.Wrapper = Styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
`;

internals.LoaderWrapper = Styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    background: ${({ theme }) => theme.palette.primary.main};
`;

internals.TabLabel = Styled.span`
    text-transform: none;
`;

internals.NullTab = Styled(Tab)`
    // !important because Mui internally
    // sets styles on 'display'
    display: none !important;
`;

internals.WhiteMenuIcon = Styled(MenuIcon)`
    color: ${({ theme }) => theme.palette.common.white};
`;

internals.MaxWidth = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin: auto;
`;

internals.TitleArea = Styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-family: ${Fonts.headerFont};
    font-weight: bold;
    position: relative;
    h1 {
        font-size: 24px;
        margin: 0;
    }
`;

internals.Greeting = Styled.div`
    position: relative;
    left: 0px;
    top: 5px;
    font-size: 13px;
    font-weight: bold;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 30%;
    @media (min-width: 600px) {
        position: absolute;
        left: 5px;
        top: unset;
    }
`;

internals.ProfileButton = Styled(IconButton)`
    position: absolute !important;
    color: #ffffff;
    right: 85%;
    @media (min-width: 600px) {
        right: 90%;
    }
    &:focus-visible {
        background-color:rgba(255, 255, 255, 0.4);
    }
`;

internals.HomeButton = Styled(IconButton)`
    position: absolute !important;
    color: #ffffff;
    left: 85%;
    @media (min-width: 600px) {
        left: 90%;
    }

    &:focus-visible {
        background-color: rgba(255, 255, 255, 0.4);
    }
`;

internals.styles = {
    appContextWrapper: {
        padding: '12px 16px 0px'
    },
    scrollPadding: {
        width: '100%'
    },
    logomark: {
        height: 25
    },
    tab: {
        background: 'none'
    },
    snackbar: {
        zIndex: 3 // Below nav tabs and above BottomButton
    },
    menuDivider: {
        marginTop: 5,
        marginBottom: 8
    },
    greetingMenuItem: {
        fontWeight: 'bold',
        color: 'inherit'
    }
};
