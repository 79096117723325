const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Paper } = require('@mui/material/Paper');
const { default: Chip } = require('@mui/material/Chip');
const Loader = require('components/Loader');
const FilterDialog = require('containers/FilterDialog');
const { default: Classes } = require('./styles.scss');
const Fonts = require('styles/fonts.json');
const ScrollPortal = require('components/ScrollPortal');

const { default: SearchIcon } = require('@mui/icons-material/Search');
const SearchResultGrid = require('components/SearchResultGrid');
const { analyticsTemplates } = require('utils/analytics');
const { USERS_SORT_TYPE } = require('utils/constants');
const { default: Button } = require('@mui/material/Button');

const { default: CancelIcon } = require('@mui/icons-material/Cancel');
const { default: RightArrow } = require('@mui/icons-material/ArrowRightAlt');
const _capitalize = require('lodash/capitalize');
const { default: Select } = require('@mui/material/Select');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: MuiCircularProgress } = require('@mui/material/CircularProgress');
const { NavLink: Link } = require('react-router-dom');
const { default: styled } = require('styled-components');
const { ELEMENT_IDS } = require('utils/constants');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');
const { default: InputLabel } = require('@mui/material/InputLabel');
const { Grid } = require('@mui/material');

const { createRef } = React;

const SEARCH_LOADER_SIZE = 20;

const internals = {};

const StyledButton = styled(Button)({
    fontFamily: Fonts.headerFont,
    fontWeight: 'bold',
    textTransform: 'none',
    letterSpacing: 1
});

class DiscoverPage extends React.Component {

    static propTypes = {
        searching: T.bool, // Is there a search query or are we just showing some suggestions?
        initLoading: T.bool,
        loading: T.bool,
        loadingNextPage: T.bool,
        interestsLoading: T.bool,
        results: T.arrayOf(T.shape({
            id: T.any.isRequired
        })),
        restoreScroll: T.func,
        rememberScroll: T.func,
        canSearchHousing: T.bool,
        hasPassionInterests: T.bool,
        discoverSortType: T.string,
        canSearchHometown: T.bool,
        showLoadMore: T.bool,
        interestsActiveFilter: T.array,
        badges: T.array,
        onClickResetSearchFilters: T.func,
        onClickLoadMore: T.func,
        onSubmitSearch: T.func,
        onClickChangeSortType: T.func,
        rolePermissions: T.shape({
            id: T.number,
            name: T.string,
            label: T.string,
            schoolId: T.number,
            canViewBadge: T.bool,
            canSeeDisabledUsers: T.bool
        }),
        departments: T.arrayOf(
            T.shape({
                id: T.number,
                name: T.string
            })
        ),
        officesList: T.arrayOf(
            T.shape({
                id: T.number,
                name: T.string
            })
        ),
        yearsHiredList: T.arrayOf(
            T.shape({
                id: T.number,
                year: T.number
            })
        ),
        schoolRoleGroups: T.array,
        isCompany: T.bool,
        isCommunity: T.bool,
        isSchoolOnline: T.bool,
        classmateDiscoverSearchFilter: T.object,
        lastSearch: T.object,
        majors: T.arrayOf(T.shape({
            id: T.number,
            name: T.string
        })),
        setAppContextFilter: T.func
    }

    constructor() {

        super();

        this.state = {
            filtersOpen: false,
            addPassionsDialogOpen: false,
            selectedRoleGroup: 'all'
        };

        this.openFilters = this._openFilters.bind(this);
        this.closeFilters = this._closeFilters.bind(this);
        this.submitLastSearch = this._submitLastSearch.bind(this);
        this.renderActiveFilters = this._renderActiveFilters.bind(this);
        this.onChangeSortType = this._onChangeSortType.bind(this);

        this.scrollContainer = document.getElementById(ELEMENT_IDS.scrollContainer);

        this.resultsWrapperRef = createRef();
    }

    _openFilters() {

        analyticsTemplates.buttons('search peers', 'discover: search peers begin');
        this.setState({ filtersOpen: true });
    }

    _closeFilters() {

        this.setState({ filtersOpen: false });
    }

    scrollToTop = () => {

        this.scrollContainer.scrollTop = 0;
    }

    onFilterUpdate = () => {

        this.scrollToTop();
        this.submitLastSearch();
    }

    _submitLastSearch() {

        const {
            lastSearch,
            onSubmitSearch,
            discoverSortType
        } = this.props;

        const newCriteria = lastSearch.criteria;
        onSubmitSearch(newCriteria, discoverSortType);
    }

    _onChangeSortType(event) {

        const {
            onClickChangeSortType,
            hasPassionInterests
        } = this.props;

        this.scrollToTop();

        const newSortType = event.target.value;

        if (newSortType === USERS_SORT_TYPE.PASSION && !hasPassionInterests) {
            // show pop up to add passions
            this.setState({
                addPassionsDialogOpen: true
            });
        }
        else {
            onClickChangeSortType(newSortType);
        }
    }

    _renderActiveFilters() {

        const {
            classmateDiscoverSearchFilter,
            badges,
            interestsActiveFilter,
            departments,
            discoverSortType,
            yearsHiredList,
            officesList,
            isCompany,
            isCommunity,
            isSchoolOnline,
            schoolRoleGroups,
            majors
        } = this.props;

        const isFilteringResults = classmateDiscoverSearchFilter && !(Object.keys(classmateDiscoverSearchFilter).length === 0) && !classmateDiscoverSearchFilter.allByMatches;

        let discoverSortTypeTitle = null;

        switch (discoverSortType) {
            case USERS_SORT_TYPE.BEST_MATCH:
                discoverSortTypeTitle = 'Best Match';
                break;
            case USERS_SORT_TYPE.PASSION:
                discoverSortTypeTitle = 'Passion Match';
                break;
            case USERS_SORT_TYPE.HOMETOWN:
                discoverSortTypeTitle = 'Hometown';
                break;
            case USERS_SORT_TYPE.HOUSING:
                discoverSortTypeTitle = 'Lives Nearby';
                break;
            case USERS_SORT_TYPE.NEWEST:
                discoverSortTypeTitle = 'Newest';
                break;
            case USERS_SORT_TYPE.ALPHABETICAL:
                discoverSortTypeTitle = 'Name';
                break;
            case USERS_SORT_TYPE.RANDOM:
                discoverSortTypeTitle = 'Surprise me';
                break;
            case USERS_SORT_TYPE.CONNECTION_STATUS:
                discoverSortTypeTitle = 'Invitations';
                break;
            case USERS_SORT_TYPE.MY_CONNECTIONS:
                discoverSortTypeTitle = 'My Connections';
                break;
            default:
                discoverSortTypeTitle = 'Best Match';
                break;
        }

        let interestTitle = null;

        if (interestsActiveFilter.length) {
            if (interestsActiveFilter.length === 1) {
                interestTitle = interestsActiveFilter[0].name;
            }
            else {
                interestTitle = 'Multiple Interests Chosen';
            }
        }

        let selectedBadge = null;

        if (classmateDiscoverSearchFilter && classmateDiscoverSearchFilter.badge) {

            const badgeItem = badges.find(({ name }) => {

                return name === classmateDiscoverSearchFilter.badge;
            });

            if (badgeItem) {
                selectedBadge = badgeItem.label;
            }
        }

        let roleText = null;
        if (classmateDiscoverSearchFilter && classmateDiscoverSearchFilter.contextFilter) {

            const activeRoleGroup = schoolRoleGroups.find((roleGroup) => roleGroup.name === classmateDiscoverSearchFilter.contextFilter) || null;

            roleText = activeRoleGroup ? activeRoleGroup.label : '';
        }

        let timeStatusText = null;
        if (classmateDiscoverSearchFilter && classmateDiscoverSearchFilter.fullTimeStatus) {

            timeStatusText = _capitalize(classmateDiscoverSearchFilter.fullTimeStatus);
        }

        let isTransferText = null;
        if (classmateDiscoverSearchFilter && classmateDiscoverSearchFilter.isTransfer) {

            isTransferText = 'No';
            if (classmateDiscoverSearchFilter.isTransfer === 'true') {
                isTransferText = 'Yes';
            }
        }

        let isOnlineText = null;
        if (classmateDiscoverSearchFilter && classmateDiscoverSearchFilter.isOnline) {

            isOnlineText = 'No';
            if (classmateDiscoverSearchFilter.isOnline === 'true') {
                isOnlineText = 'Yes';
            }
        }

        let openSocialText = null;
        if (classmateDiscoverSearchFilter && classmateDiscoverSearchFilter.openSocial) {

            openSocialText = 'No';
            if (classmateDiscoverSearchFilter.openSocial === 'true') {
                openSocialText = 'Yes';
            }
        }

        let veteranText = null;
        if (classmateDiscoverSearchFilter && classmateDiscoverSearchFilter.isVeteran) {

            veteranText = 'No';
            if (classmateDiscoverSearchFilter.isVeteran === 'true') {
                veteranText = 'Yes';
            }
        }

        let searchIsParentText = null;
        if (classmateDiscoverSearchFilter && classmateDiscoverSearchFilter.searchIsParent) {

            searchIsParentText = 'No';
            if (classmateDiscoverSearchFilter.searchIsParent === 'true') {
                searchIsParentText = 'Yes';
            }
        }

        let workRemoteText = null;
        if (classmateDiscoverSearchFilter && classmateDiscoverSearchFilter.workRemote) {

            workRemoteText = 'No';
            if (classmateDiscoverSearchFilter.workRemote === 'true') {
                workRemoteText = 'Yes';
            }
        }

        let yearHiredText = null;
        if (classmateDiscoverSearchFilter && classmateDiscoverSearchFilter.yearHiredId) {

            const selectedYear = yearsHiredList.find((item) => {

                return item.id === classmateDiscoverSearchFilter.yearHiredId;
            });

            if (selectedYear) {
                yearHiredText = selectedYear.year;
            }
        }

        let officeText = null;
        if (classmateDiscoverSearchFilter && classmateDiscoverSearchFilter.officeId) {

            const selectedOffice = officesList.find((item) => {

                return item.id === classmateDiscoverSearchFilter.officeId;
            });

            if (selectedOffice) {
                officeText = selectedOffice.name;
            }
        }

        let departmentText = null;
        if (classmateDiscoverSearchFilter && classmateDiscoverSearchFilter.departmentId) {

            const selectedDepartment = departments.find((item) => {

                return item.id === classmateDiscoverSearchFilter.departmentId;
            });

            if (selectedDepartment) {
                departmentText = selectedDepartment.name;
            }
        }

        if (isFilteringResults) {
            let firstMajor = classmateDiscoverSearchFilter.major ? classmateDiscoverSearchFilter.major[0] : null;

            if (firstMajor) {
                firstMajor = majors.find(({ id }) => id === firstMajor)?.name;
            }

            const activeFilters = [
                {
                    label: 'Name',
                    value: classmateDiscoverSearchFilter.name ? classmateDiscoverSearchFilter.name : null
                }, {
                    label: 'Age',
                    value: classmateDiscoverSearchFilter.age ? classmateDiscoverSearchFilter.age : null
                }, {
                    label: 'Career',
                    value: classmateDiscoverSearchFilter.career ? classmateDiscoverSearchFilter.career : null
                }, {
                    label: 'Profession',
                    value: classmateDiscoverSearchFilter.profession ? classmateDiscoverSearchFilter.profession : null
                }, {
                    label: 'Bio',
                    value: classmateDiscoverSearchFilter.bio ? classmateDiscoverSearchFilter.bio : null
                }, {
                    label: 'Department',
                    value: departmentText
                }, {
                    label: isCompany ? 'Affiliated Office' : 'Campus',
                    value: officeText
                }, {
                    label: 'Year Hired',
                    value: yearHiredText
                }, {
                    label: 'Work Remote',
                    value: workRemoteText
                }, {
                    label: 'Title',
                    value: classmateDiscoverSearchFilter.title ? classmateDiscoverSearchFilter.title : null
                }, {
                    label: 'Badge',
                    value: selectedBadge
                }, {
                    label: 'Radius',
                    value: classmateDiscoverSearchFilter.radius ? classmateDiscoverSearchFilter.radius : null
                }, {
                    label: 'Location',
                    value: classmateDiscoverSearchFilter.locationType ? (classmateDiscoverSearchFilter.locationType === 'housing' ? 'Where I Live' : ((isCompany || isCommunity || isSchoolOnline) ? 'Where I Grew Up' : 'My Hometown')) : null
                }, {
                    label: 'Transfer Student',
                    value: isTransferText
                }, {
                    label: 'Online Student',
                    value: isOnlineText
                }, {
                    label: 'Open to Social',
                    value: openSocialText
                }, {
                    label: 'Veteran',
                    value: veteranText
                }, {
                    label: 'Parent',
                    value: searchIsParentText
                }, {
                    label: 'Full / Part-time',
                    value: timeStatusText
                }, {
                    label: 'Incoming Class',
                    value: classmateDiscoverSearchFilter.incomingClass ? classmateDiscoverSearchFilter.incomingClass : null
                }, {
                    label: 'Graduating Class',
                    value: classmateDiscoverSearchFilter.graduatingClass ? classmateDiscoverSearchFilter.graduatingClass : null
                }, {
                    label: 'Major',
                    value: firstMajor || null
                }, {
                    label: 'Student Name',
                    value: classmateDiscoverSearchFilter.studentName ? classmateDiscoverSearchFilter.studentName : null
                }, {
                    label: 'Interests',
                    value: interestTitle
                }
            ];

            return activeFilters;
        }

        return [];
    }

    componentDidMount() {
        this.props.restoreScroll();

        if (this.props.classmateDiscoverSearchFilter &&
            this.props.classmateDiscoverSearchFilter.contextFilter) {
            this.setState({
                selectedRoleGroup: this.props.classmateDiscoverSearchFilter.contextFilter
            });
        }
    }

    componentWillUnmount() {

        this.props.rememberScroll();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (!nextProps.loadingNextPage && this.props.loadingNextPage) {
            AnimatedFocusIndicator.onBlurHandler();
            // console.log('zlog this.lastItemEl', this.lastItemEl);

            // TODO left off here
            // setTimeout(() => {

            //     this.lastItemEl.nextElementSibling.focus();
            // }, 100);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.classmateDiscoverSearchFilter?.contextFilter !==
            this.props.classmateDiscoverSearchFilter?.contextFilter) {
            this.setState({
                selectedRoleGroup: this.props.classmateDiscoverSearchFilter?.contextFilter || 'all'
            });
        }
    }

    loadMore = (evt) => {

        const resultsBeforeFetch = Array.from(this.resultsWrapperRef.current.querySelectorAll('ul li'));

        this.lastItemEl = resultsBeforeFetch[resultsBeforeFetch.length - 1];

        this.props.onClickLoadMore(evt);
    }

    render() {

        const {
            styles,
            Wrapper,
            FindConnect,
            ResultsWrapper,
            CircularProgress
        } = internals;

        const { filtersOpen } = this.state;

        const {
            searching,
            initLoading,
            loading,
            loadingNextPage,
            interestsLoading,
            results,
            canSearchHousing,
            canSearchHometown,
            onSubmitSearch,
            classmateDiscoverSearchFilter,
            rolePermissions: { canSeeDisabledUsers },
            discoverSortType,
            showLoadMore,
            schoolRoleGroups
        } = this.props;

        const isFilteringResults = classmateDiscoverSearchFilter
            && !(Object.keys(classmateDiscoverSearchFilter).length === 0)
            && !classmateDiscoverSearchFilter.allByMatches;

        return <Wrapper>
            <ScrollPortal top>
                <div style={internals.styles.searchContainer}>
                    <div style={isFilteringResults ? internals.styles.searchBarWithFilters : internals.styles.searchBar}>
                        <button
                            style={{
                                ...internals.styles.searchBarContent,
                            }}
                            className={Classes.searchBarContent}
                            onClick={this.openFilters}
                        >
                            <SearchIcon style={internals.styles.searchIcon} />
                            {isFilteringResults ? (
                                <div style={internals.styles.chipContainer}>
                                    {this.renderActiveFilters().filter((filter) =>
                                        filter.value !== undefined &&
                                        filter.value !== null &&
                                        filter.value !== false
                                    ).map((filter, index) => (
                                        <Chip
                                            key={index}
                                            className={Classes.activeFilter}
                                            color={'primary'}
                                            label={`${filter.label}: ${filter.value}`}
                                            size="small"
                                            style={{ fontSize: '13px', minHeight: '28px', display: 'flex', alignItems: 'center' }}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <span style={internals.styles.searchText}>
                                    Search for people, interests, majors,...
                                </span>
                            )}
                        </button>
                        {isFilteringResults && (
                            <Button
                                color="secondary"
                                onClick={() => {
                                    this.props.onClickResetSearchFilters(discoverSortType);
                                    AnimatedFocusIndicator.onBlurHandler();
                                }}
                                startIcon={<CancelIcon />}
                                style={internals.styles.clearButton}
                                sx={{
                                    ml: 1,
                                    "&.MuiButtonBase-root:hover": {
                                        bgcolor: "transparent"
                                    }
                                }}
                            >
                            </Button>
                        )}
                    </div>

                    <div style={internals.styles.filtersRow}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Grid container alignItems={'center'} spacing={2}>
                                <Grid item>
                                    <InputLabel id="sort-by-select" style={{ position: 'static', transform: 'none' }}>Sort By:</InputLabel>
                                </Grid>
                                <Grid item>
                                    <FormControl style={{ minWidth: 150 }}>
                                        <Select
                                            labelId="sort-by-select"
                                            inputProps={{
                                                'aria-labelledby': 'sort-by-select'
                                            }}
                                            value={discoverSortType}
                                            onChange={this.onChangeSortType}
                                        >
                                            <MenuItem value={USERS_SORT_TYPE.BEST_MATCH}>
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        align: 'left'
                                                    }}
                                                    primary={'Best Match'} />
                                            </MenuItem>
                                            <MenuItem value={USERS_SORT_TYPE.PASSION}>
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        align: 'left'
                                                    }}
                                                    primary={'Passion Match'} />
                                            </MenuItem>
                                            {canSearchHometown && <MenuItem value={USERS_SORT_TYPE.HOMETOWN}>
                                                <ListItemText
                                                    primary={'Hometown'}
                                                    primaryTypographyProps={{
                                                        align: 'left'
                                                    }}
                                                />
                                            </MenuItem>}
                                            {canSearchHousing && <MenuItem value={USERS_SORT_TYPE.HOUSING}>
                                                <ListItemText
                                                    primary={'Lives Nearby'}
                                                    primaryTypographyProps={{
                                                        align: 'left'
                                                    }}
                                                />
                                            </MenuItem>}
                                            <MenuItem value={USERS_SORT_TYPE.NEWEST}>
                                                <ListItemText
                                                    primary={'Newest'}
                                                    primaryTypographyProps={{
                                                        align: 'left'
                                                    }}
                                                />
                                            </MenuItem>
                                            <MenuItem value={USERS_SORT_TYPE.ALPHABETICAL}>
                                                <ListItemText
                                                    primary={<div style={{ display: 'flex', alignItems: 'center' }}>Name (A <RightArrow /> Z)</div>}
                                                    primaryTypographyProps={{
                                                        align: 'left'
                                                    }}
                                                />
                                            </MenuItem>
                                            <MenuItem value={USERS_SORT_TYPE.RANDOM}>
                                                <ListItemText
                                                    primary={'Surprise Me'}
                                                    primaryTypographyProps={{
                                                        align: 'left'
                                                    }}
                                                />
                                            </MenuItem>
                                            <MenuItem value={USERS_SORT_TYPE.CONNECTION_STATUS}>
                                                <ListItemText
                                                    primary={'Invitations'}
                                                    primaryTypographyProps={{
                                                        align: 'left'
                                                    }}
                                                />
                                            </MenuItem>
                                            <MenuItem value={USERS_SORT_TYPE.MY_CONNECTIONS}>
                                                <ListItemText
                                                    primary={'My Connections'}
                                                    primaryTypographyProps={{
                                                        align: 'left'
                                                    }}
                                                />
                                            </MenuItem>
                                            {canSeeDisabledUsers ? <MenuItem value={USERS_SORT_TYPE.DISABLED}>
                                                <ListItemText
                                                    primary={'Closed Accounts'}
                                                    primaryTypographyProps={{
                                                        align: 'left'
                                                    }}
                                                />
                                            </MenuItem> : null}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                            <Grid container alignItems={'center'} spacing={2}>
                                <Grid item>
                                    <InputLabel id="role-select" style={{ position: 'static', transform: 'none' }}>Role:</InputLabel>
                                </Grid>
                                <Grid item>
                                    <FormControl style={{ minWidth: 150 }}>
                                        <Select
                                            value={this.state.selectedRoleGroup}
                                            onChange={(event) => {
                                                const selectedValue = event.target.value;

                                                const newCriteria = {
                                                    ...this.props.lastSearch.criteria,
                                                    contextFilter: selectedValue === 'all' ? null : selectedValue
                                                };

                                                this.setState({ selectedRoleGroup: selectedValue });

                                                this.props.setAppContextFilter({ filter: selectedValue });

                                                this.props.onSubmitSearch(newCriteria, discoverSortType);

                                                this.scrollToTop();
                                            }}
                                        >
                                            <MenuItem value="all">
                                                <ListItemText
                                                    primary={'All'}
                                                    primaryTypographyProps={{
                                                        align: 'left'
                                                    }}
                                                />
                                            </MenuItem>
                                            {schoolRoleGroups.map((roleGroup) => (
                                                <MenuItem
                                                    key={roleGroup.name}
                                                    value={roleGroup.name}
                                                >
                                                    <ListItemText
                                                        primary={roleGroup.label}
                                                        primaryTypographyProps={{
                                                            align: 'left'
                                                        }}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </ScrollPortal>
            {!filtersOpen && <div className={`${Classes.main} ${Classes.noPaddingMain}`}>
                <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', left: '50%', top: 70 }}>
                        {(!!initLoading || !!loading || !!interestsLoading) && <Loader />}
                    </div>
                </div>
                <div>
                    {!loading && (!results || !results.length) && <div className={Classes.noResults}>No peers found</div>}
                    {results && !loading && <ResultsWrapper ref={this.resultsWrapperRef}>
                        <SearchResultGrid
                            results={results}
                            showConnectionStatus={discoverSortType === USERS_SORT_TYPE.CONNECTION_STATUS}
                            showDistance={discoverSortType === USERS_SORT_TYPE.HOMETOWN || discoverSortType === USERS_SORT_TYPE.HOUSING}
                        />
                    </ResultsWrapper>}
                </div>
            </div>}
            {!!showLoadMore && !loading &&
                <Button
                    // Yeah these are inline styles, we'll copy paste them for the upgrade branch
                    style={{ ...styles.button, margin: '0 32px 32px 32px' }}
                    color={'primary'}
                    onClick={this.loadMore}
                    data-focus-outline='radius:40,padding:-1'
                >
                    Load More Results {loadingNextPage && <CircularProgress size={SEARCH_LOADER_SIZE} />}
                </Button>
            }

            <Dialog
                open={this.state.addPassionsDialogOpen}
                onClose={() => {

                    this.setState({
                        addPassionsDialogOpen: false
                    });
                }}
                aria-labelledby="alert-add-passions"
                aria-describedby="alert-add-passions-description"
            >
                <DialogTitle id="alert-add-passions-title">{'Add Passion Interests'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-Inappropriate-Message-description">
                        To match with others who share your passions, first <Link
                            to={{
                                pathname: '/app/profile/edit/interests',
                                state: {
                                    scrollToInterests: true
                                }
                            }}>edit your profile</Link> to designate up to 5 of your Interests as Passions.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {

                        this.setState({
                            addPassionsDialogOpen: false
                        });
                    }} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <FilterDialog
                open={filtersOpen}
                onSubmit={onSubmitSearch}
                onRequestClose={this.closeFilters}
                canSearchHousing={canSearchHousing}
                canSearchHometown={canSearchHometown}
                isBlankSearch={(!isFilteringResults)}
            />
        </Wrapper>;
    }
}

module.exports = DiscoverPage;

internals.styles = {
    searchContainer: {
        padding: '20px 32px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    searchBar: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F7F4FF',
        borderRadius: '8px',
        padding: '12px 16px',
        cursor: 'pointer',
        border: 'none'
    },
    searchBarWithFilters: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#F7F4FF',
        borderRadius: '8px',
        padding: '12px 16px',
        minHeight: '48px',
        border: 'none'
    },
    searchBarContent: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        gap: '12px',
        cursor: 'pointer',
        border: 'none',
        background: 'transparent',
        width: '100%',
        padding: 0
    },
    searchIcon: {
        color: '#6E56CF',
        marginRight: '12px',
        flexShrink: 0,
        marginTop: '3px'
    },
    searchText: {
        color: '#6E56CF',
        opacity: 0.8,
        fontSize: '16px',
        justifyContent: 'center'
    },
    chipContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '6px',
        alignItems: 'center',
        overflow: 'hidden',
        maxWidth: 'calc(100% - 40px)'
    },
    clearButton: {
        padding: '4px',
        minWidth: 'unset',
        borderRadius: '50%',
        width: '32px',
        height: '32px'
    },
    filtersRow: {
        display: 'flex',
        gap: '16px',
        alignItems: 'center'
    },
    button: {
        minWidth: '50%',
        display: 'inline-block',
        border: '1px solid',
        lineHeight: '34px' // Adjust for border
    },
    buttonLabel: {
        fontFamily: Fonts.headerFont,
        fontWeight: 'bold',
        textTransform: 'none',
        letterSpacing: 1
    }
};

internals.Wrapper = Styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

internals.FindConnect = Styled.h2`
    && {
        position: relative;
        font-size: 20px;
        font-weight: 400;
        line-height: 23px;
        margin: 0;
        padding: 22px 0px 10px;
    }
`;

internals.ResultsWrapper = Styled.div`
    ul {
        margin-top: 5px;
    }
`;

internals.CircularProgress = Styled(MuiCircularProgress)`
    position: absolute;
    width: ${SEARCH_LOADER_SIZE}px;
    height: ${SEARCH_LOADER_SIZE}px;
    top: 12px;
    margin-left: 12px;
`;
