const React = require('react');
const T = require('prop-types');
const { default: Grid } = require('@mui/material/Grid');
const { default: List } = require('@mui/material/List');
const { default: Tabs } = require('@mui/material/Tabs');
const { default: Tab } = require('@mui/material/Tab');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: Button } = require('@mui/material/Button');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: Paper } = require('@mui/material/Paper');
const { default: Typography } = require('@mui/material/Typography');
const { default: styled } = require('styled-components');
const { MAX_CONTENT_WIDTH } = require('utils/constants');
const { default: ReactWordcloud } = require('react-wordcloud');
const { createRef } = require('react');
const Loader = require('../../../../../components/Loader');
const { Pie, Line } = require('react-chartjs-2');
const { MapContainer, TileLayer, Popup, CircleMarker } = require('react-leaflet');
const { getOriginalImageUrl } = require('../../../../../utils/image');

require('leaflet/dist/leaflet.css');
require('chartjs-plugin-piechart-outlabels');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: HelpOutlineIcon } = require('@mui/icons-material/HelpOutline');
const { default: Tooltip } = require('@mui/material/Tooltip');
const { default: Select } = require('@mui/material/Select');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: InputLabel } = require('@mui/material/InputLabel');
const { NavLink: Link } = require('react-router-dom');
const { default: ChatIcon } = require('@mui/icons-material/Chat');

const internals = {};

internals.DashboardWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    min-width: 100vw;
    background-color: white;
    left: calc(((100vw - ${MAX_CONTENT_WIDTH}px) / 2) * -1);
    top: 0;

    @media (max-width: 600px) {
        left: 0;
        width: 100%;
    }
`;

internals.StyledPaper = styled(Paper)`
    && {
        padding-left: 1.5em;
        padding-right: 1.5em;
        padding-top: 1.25em;
        padding-bottom: 1.25em;
        margin: 0.5em;
        margin-top: 1.5em;
        height: 120px;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: space-between;
    }
`;

internals.IconWrapper = styled.div`
    width: 36px;
    display: flex;
    align-items: flex-start;
    padding-top: 2px;
`;

internals.StyledIcon = styled.img`
    width: 32px;
    height: 32px;
`;

internals.InterestText = styled.span`
    display: block;
    padding-top: 6px;
    margin-left: 12px;
`;

internals.styles = {
    chartContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column'
    }
};

internals.SchoolHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 1em;
    margin-left: 1.2em;
    margin-top: 2em;
`;

internals.SchoolImage = styled.img`
    max-height: 50px;
    width: auto;
    object-fit: contain;
`;

internals.DialogContainer = styled.div`
    height: 80vh;
    display: flex;
    flex-direction: column;
`;

internals.DialogContentWrapper = styled.div`
    height: 100%;
    position: relative;
`;

internals.ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
    position: relative;
    z-index: 2;
`;

internals.ChartContainer = styled(internals.StyledPaper)`
    && {
        padding-left: 2em;
        padding-right: 2em;
        padding-top: ${(props) => props.$isMap ? '0' : '0.5em'};
        padding-bottom: 2em;
        width: 670px;
        height: ${(props) => props.$isMap ? '400px' : 'auto'};
        overflow: ${(props) => props.$isMap ? 'hidden' : 'visible'};
    }
`;

internals.InterestsList = styled(List)`
    && {
        padding-top: 0.75em;
    }
`;

internals.InterestsSection = styled.div`
    margin-top: 1em;
    margin-bottom: 2em;
    margin-left: 2em;
    margin-right: 2em;
`;

internals.MainContentWrapper = styled.div`
    border-left: 1px solid #e0e0e0;
    background-color: #fafafa;
    padding-left: 1.5em;
`;

internals.ContentLayout = styled.div`
    display: flex;
    gap: 1em;
`;

internals.ContentSection = styled.div`
    flex: 1;
`;

internals.AnalyticsGrid = styled(Grid)`
    && {
        margin-top: 0.5em;
        padding: ${({ theme }) => theme.spacing(2)};
    }
`;

internals.UserAnalysisWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-right: 2em;
    margin-left: 2em;
`;

internals.UserAnalysisTitle = styled(Typography)`
    && {
        margin-bottom: 1em;
        margin-top: 2em;
    }
`;

internals.UserAnalysisButtons = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;

internals.FilterContainer = styled.div`
    display: flex;
    gap: 1em;
    margin-bottom: 1em;
    padding: 0 1.2em;
`;

internals.StyledFormControl = styled(FormControl)`
    && {
        min-width: 150px;
    }
`;

internals.MapWrapper = styled.div`
    height: 100%;
    .leaflet-control-attribution {
        display: none;
    }
`;

internals.GroupTitle = styled(Typography)`
    && {
        margin-top: 1em;
        margin-bottom: 0.5em;
        margin-left: 1em;
        font-size: 1.1rem;
        color: #666;
    }
`;

internals.StyledListItemText = styled(ListItemText)`
    && {
        .MuiListItemText-primary {
            display: flex;
            align-items: flex-start;
            gap: 8px;
        }
    }
`;

internals.LegendContainer = styled.div`
    width: 200px;
    padding: 20px;
    height: auto;
    position: relative;
`;

internals.LegendLabel = styled.span`
    word-wrap: break-word;
    white-space: normal;
    line-height: 1.2;
`;

const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
};

const groupCoordinates = (coordinates) => {
    const groups = [];
    const maxDistance = 300;

    coordinates.forEach((coord) => {
        const foundGroup = groups.find((group) =>
            calculateDistance(group.lat, group.lng, coord.lat, coord.lng) <= maxDistance
        );

        if (foundGroup) {
            foundGroup.count++;
            foundGroup.lat = (foundGroup.lat * (foundGroup.count - 1) + coord.lat) / foundGroup.count;
            foundGroup.lng = (foundGroup.lng * (foundGroup.count - 1) + coord.lng) / foundGroup.count;
        }
        else {
            groups.push({
                lat: coord.lat,
                lng: coord.lng,
                count: 1
            });
        }
    });

    return groups;
};

const getMarkerRadius = (count, allCounts) => {
    const maxCount = Math.max(...allCounts);
    const minCount = Math.min(...allCounts);

    const minRadius = 5;
    const maxRadius = 25;

    if (maxCount === minCount) {
        return (minRadius + maxRadius) / 2;
    }

    const scale = (count - minCount) / (maxCount - minCount);
    return minRadius + (maxRadius - minRadius) * scale;
};

const MemoizedWordcloud = React.memo(({ words, options }) => {
    return (
        <ReactWordcloud
            words={words}
            options={options}
            maxWords={50}
            minSize={[300, 300]}
        />
    );
}, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.words) === JSON.stringify(nextProps.words);
});

MemoizedWordcloud.propTypes = {
    words: T.arrayOf(T.shape({
        text: T.string.isRequired,
        value: T.number.isRequired
    })).isRequired,
    options: T.object.isRequired
};

class AdminDashboard extends React.PureComponent {
    static propTypes = {
        userStats: T.shape({
            newUsers: T.number,
            schoolSvg: T.shape({
                svg: T.string
            }),
            connectionsMade: T.number,
            total: T.number,
            badges: T.arrayOf(T.shape({
                type: T.string,
                count: T.number
            })),
            incomingClass: T.arrayOf(T.shape({
                type: T.string,
                count: T.number
            })),
            interests: T.arrayOf(T.shape({
                name: T.string,
                count: T.number
            })),
            groupMessages: T.number,
            directMessages: T.number,
            graduatingClass: T.oneOfType([T.string, T.array]),
            gender: T.oneOfType([T.string, T.array]),
            transfer: T.oneOfType([T.string, T.array]),
            averageUserStats: T.shape({
                averages: T.shape({
                    messagesPerUser: T.number,
                    groupsPerUser: T.number,
                    connectionsPerUser: T.number,
                    totals: T.number,
                    trueTotals: T.number,
                    connections: T.number,
                    interests: T.number,
                    groupMessages: T.number,
                    directMessages: T.number
                })
            }),
            userGrowth: T.arrayOf(T.shape({
                date: T.string,
                count: T.number
            }))
        }),
        messagesStats: T.shape({
            total: T.number,
            dailyActivity: T.shape({
                groupTotal: T.number,
                dmsTotal: T.number
            }),
            byGroup: T.arrayOf(T.shape({
                conversationId: T.oneOfType([T.string, T.number]),
                className: T.string,
                count: T.number,
                participantCount: T.number,
                topWords: T.arrayOf(T.shape({
                    word: T.string,
                    count: T.number
                }))
            }))
        }),
        schools: T.arrayOf(T.shape({
            id: T.number,
            name: T.string
        })).isRequired,
        currentUser: T.shape({
            email: T.string,
            schoolId: T.number
        }),
        fetchUserStats: T.func.isRequired,
        fetchMessagesStats: T.func.isRequired
    }

    constructor(props) {
        super(props);

        this.dialogRef = createRef();
        this.chartRef = createRef();

        this.showModal = this._showModal.bind(this);
        this.closeModal = this._closeModal.bind(this);
        this.handleGroupSelect = this._handleGroupSelect.bind(this);
        this.handleGroupsDialogOpen = this._handleGroupsDialogOpen.bind(this);
        this.handleGroupsDialogClose = this._handleGroupsDialogClose.bind(this);
        this.toggleExpandOthers = this._toggleExpandOthers.bind(this);
        this.createPieData = this._createPieData.bind(this);
        this.getRandomColor = this._getRandomColor.bind(this);
        this.renderUserInfoButtons = this._renderUserInfoButtons.bind(this);
        this.renderUserInfoModal = this._renderUserInfoModal.bind(this);
        this.renderGroupsDialog = this._renderGroupsDialog.bind(this);
        this._renderAverageUserSection = this._renderAverageUserSection.bind(this);
        this.handleFilterChange = this._handleFilterChange.bind(this);
        this.handleSchoolChange = this._handleSchoolChange.bind(this);
    }

    state = {
        modalVisible: false,
        currentChartData: null,
        currentChartTitle: null,
        expandOthers: false,
        loading: true,
        groupsDialogOpen: false,
        selectedGroup: null,
        activeTab: 0,
        showAverages: false,
        selectedIncomingClass: '',
        selectedGender: '',
        selectedTransfer: '',
        tempIncomingClass: '',
        tempGender: '',
        tempTransfer: '',
        filteredUserCount: 0,
        totalUserCount: 0,
        activeFilters: [],
        selectedSchoolId: this.props.currentUser?.schoolId || ''
    }

    componentDidMount() {
        if (this.state.selectedSchoolId) {
            this._fetchStats();
        }
    }

    _handleSchoolChange = (event) => {
        const schoolId = event.target.value;
        this.setState({
            selectedSchoolId: schoolId,
            selectedIncomingClass: '',
            selectedGender: '',
            selectedTransfer: '',
            tempIncomingClass: '',
            tempGender: '',
            tempTransfer: '',
            activeFilters: []
        }, () => {
            this._fetchStats();
        });
    }

    _fetchStats = async () => {
        try {
            const {
                selectedIncomingClass,
                selectedGender,
                selectedTransfer,
                selectedSchoolId
            } = this.state;

            if (!selectedSchoolId) {
                throw new Error('No school selected');
            }

            this.setState({ loading: true });

            const filters = {
                schoolId: selectedSchoolId,
                incomingClass: selectedIncomingClass || undefined,
                gender: selectedGender || undefined,
                transfer: selectedTransfer || undefined
            };

            const [userStatsResponse, messagesStatsResponse] = await Promise.all([
                this.props.fetchUserStats(filters),
                this.props.fetchMessagesStats(filters)
            ]);

            this.setState({
                loading: false,
                filteredUserCount: userStatsResponse?.payload?.result?.averageUserStats?.averages?.totals || 0,
                totalUserCount: userStatsResponse?.payload?.result?.averageUserStats?.averages?.trueTotals || 0
            });
        }
        catch (error) {
            console.error('Error fetching stats:', error);
            this.setState({
                loading: false,
                error: 'Failed to load dashboard statistics. Please try again later.'
            });
        }
    }

    _handleGroupSelect(group) {
        this.setState((prevState) => ({
            selectedGroup: group
        }));
    }

    _handleGroupsDialogOpen() {
        if (!this.state.selectedGroup && this.props.messagesStats?.byGroup?.length > 0) {
            this.setState((prevState) => ({
                groupsDialogOpen: true,
                selectedGroup: this.props.messagesStats.byGroup[0]
            }));
        }
        else {
            this.setState((prevState) => ({
                groupsDialogOpen: true
            }));
        }
    }

    _handleGroupsDialogClose() {
        this.setState((prevState) => ({
            groupsDialogOpen: false,
            selectedGroup: null
        }));
    }

    _toggleExpandOthers() {
        this.setState((prevState) => ({
            expandOthers: !prevState.expandOthers
        }));
    }

    _showModal(title, data) {
        this.setState((prevState) => ({
            modalVisible: true,
            currentChartData: data,
            currentChartTitle: title
        }));
    }

    _closeModal() {
        this.setState((prevState) => ({
            modalVisible: false,
            currentChartData: null,
            currentChartTitle: null,
            expandOthers: false
        }));
    }

    chartOptions = {
        responsive: true,
        aspectRatio: 1,
        legend: {
            display: false
        },
        plugins: {
            tooltip: {
                enabled: false
            },
            legend: {
                display: true,
                position: 'left'
            }
        },
        tooltips: {
            enabled: false
        }
    }

    wordcloudOptions = {
        padding: 5,
        rotations: 0,
        rotationAngles: [0],
        deterministic: true,
        enableTooltip: true,
        fontSizes: [30, 70],
        scale: 'linear',
        spiral: 'archimedean',
        colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b']
    }

    _createPieData = (data, labelKey = 'name', valueKey = 'count') => {
        const sortedData = [...data].sort((a, b) => b[valueKey] - a[valueKey]);

        const total = sortedData.reduce((sum, item) => sum + item[valueKey], 0);
        const threshold = 0.05;

        const mainData = [];
        const otherData = [];

        const capitalizeFirstLetter = (string) => {
            if (!string) {
                return '';
            }

            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        sortedData.forEach((item) => {
            let label = capitalizeFirstLetter(item[labelKey]);
            if (item.role?.name) {
                label = capitalizeFirstLetter(item.role.name);
            }

            if (item.type) {
                if (data === this.props.userStats?.badges) {
                    label = capitalizeFirstLetter(item.type);
                }
                else {
                    label = capitalizeFirstLetter(item.type);
                }
            }

            if (item.year) {
                label = `Class of ${item.year}`;
            }

            if ('isVeteran' in item) {
                label = item.isVeteran ? 'Veteran' : 'Non-Veteran';
            }

            if ('isParent' in item) {
                label = item.isParent ? 'Parent' : 'Non-Parent';
            }

            if ('isTransfer' in item) {
                label = item.isTransfer ? 'Transfer' : 'Non-Transfer';
            }

            if ('isOnline' in item) {
                label = item.isOnline ? 'Online' : 'In-Person';
            }

            if ('isFullTime' in item) {
                label = item.isFullTime ? 'Full Time' : 'Part Time';
            }

            if ('isRemote' in item) {
                label = item.isRemote ? 'Remote' : 'On-Site';
            }

            if (item[valueKey] / total >= threshold) {
                mainData.push({ ...item, displayLabel: label });
            }
            else {
                otherData.push({ ...item, displayLabel: label });
            }
        });

        const chartData = [
            ...mainData,
            otherData.length ? {
                [valueKey]: otherData.reduce((sum, item) => sum + item[valueKey], 0),
                displayLabel: 'Other'
            } : null
        ].filter(Boolean);

        const legendData = this.state.expandOthers
            ? [...mainData, ...otherData]
            : chartData;

        const colors = [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)'
        ];

        const borderColors = [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ];

        return {
            labels: chartData.map((item) => {
                const percentage = ((item[valueKey] / total) * 100).toFixed(1);
                return `${item.displayLabel} (${item[valueKey]} - ${percentage}%)`;
            }),
            outLabels: chartData.map((item) => {
                return `${item.displayLabel}`;
            }),
            legendLabels: legendData.map((item) => {
                const percentage = ((item[valueKey] / total) * 100).toFixed(1);
                return `${item.displayLabel} (${item[valueKey]} - ${percentage}%)`;
            }),
            datasets: [{
                data: chartData.map((item) => item[valueKey]),
                backgroundColor: chartData.map((item, index) =>
                    item.displayLabel === 'Other' ? 'rgba(169, 169, 169, 0.6)' : colors[index % colors.length]
                ),
                borderColor: chartData.map((item, index) =>
                    item.displayLabel === 'Other' ? 'rgba(128, 128, 128, 1)' : borderColors[index % borderColors.length]
                ),
                borderWidth: 1,
                pieceLabel: false
            }]
        };
    }

    _getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
    }

    _renderUserInfoButtons = () => {
        const { userStats } = this.props;
        const excludedFields = ['verificationStatus', 'activityStatus', 'newUsers', 'total', 'dailyActivity', 'userGrowth', 'categories', 'interests', 'connectionsMade', 'passionInterests', 'passionCategories', 'averages', 'averageUserStats', 'customInterests', 'growthStats', 'totals', 'messagesStats', 'hometownCoordinates', 'schoolSvg'];
        const preferredOrder = [
            'roles',
            'gender',
            'ethnicity',
            'incomingClass',
            'graduatingClass',
            'parent',
            'veteran'
        ];

        const preferredButtons = [];
        const remainingButtons = [];

        Object.entries(userStats || {}).forEach(([key, value]) => {
            if (excludedFields.includes(key) || !value) {
                return;
            }

            const { title, data } = this.getButtonConfig(key, value);

            const hasNonZeroData = Array.isArray(data)
                ? data.some((item) => item.count > 0)
                : data > 0;

            if (!hasNonZeroData) {
                return;
            }

            const button = (
                <Button
                    key={key}
                    onClick={() => this.showModal(title, data)}
                    sx={{ justifyContent: 'flex-start' }}
                >
                    {title}
                </Button>
            );

            if (preferredOrder.includes(key)) {
                preferredButtons[preferredOrder.indexOf(key)] = button;
            }
            else {
                remainingButtons.push({ key, button });
            }
        });

        const filteredPreferredButtons = preferredButtons.filter(Boolean);

        const sortedRemainingButtons = remainingButtons
            .sort((a, b) => a.key.localeCompare(b.key))
            .map((item) => item.button);

        return [...filteredPreferredButtons, ...sortedRemainingButtons];
    }

    _renderUserInfoModal = () => {
        const { modalVisible, currentChartData, currentChartTitle, expandOthers } = this.state;

        if (!modalVisible || !currentChartData) {
            return null;
        }

        const pieData = this._createPieData(currentChartData);
        const chartKey = `pie-chart-${expandOthers ? 'expanded' : 'collapsed'}`;

        const chartOptionsWithLabels = {
            ...this.chartOptions,
            plugins: {
                legend: {
                    display: false
                },
                outlabels: {
                    text: (context) => {
                        const label = context.chart.data.outLabels[context.dataIndex];
                        const value = context.dataset.data[context.dataIndex];
                        return `${label} (${value})`;
                    },
                    color: 'white',
                    stretch: 25,
                    font: {
                        resizable: true,
                        minSize: 11,
                        maxSize: 11
                    },
                    textAlign: 'left',
                    lineWidth: 1,
                    padding: 4,
                    borderRadius: 3,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    borderColor: (context) => context.dataset.backgroundColor[context.dataIndex],
                    zIndex: 1,
                    lineColor: (context) => context.dataset.backgroundColor[context.dataIndex]
                }
            },
            maintainAspectRatio: false,
            responsive: true,
            layout: {
                padding: {
                    top: 50,
                    bottom: 50
                }
            }
        };

        const labels = expandOthers ? pieData.legendLabels : pieData.labels;
        const colors = pieData.datasets[0].backgroundColor;

        const totalCount = this.state.filteredUserCount.toLocaleString();
        const totalUserCount = this.state.totalUserCount.toLocaleString();
        const activeFilters = this.state.activeFilters;

        return (
            <Dialog
                open={modalVisible}
                onClose={this.closeModal}
                maxWidth="md"
                fullWidth
                aria-labelledby="chart-dialog-title"
            >
                <internals.DialogContainer
                    tabIndex={0}
                    ref={this.dialogRef}
                    data-focus-outline="radius:15"
                >
                    <DialogTitle
                        id="chart-dialog-title"
                        sx={{
                            position: 'relative',
                            zIndex: 1,
                            pb: 0,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2
                        }}
                    >
                        <div>
                            {`Students by ${currentChartTitle} (${totalCount} out of ${totalUserCount})`}
                            {activeFilters.length > 0 && (
                                <Typography
                                    variant="subtitle1"
                                    color="text.secondary"
                                    sx={{ mt: 0.5, mb: 1 }}
                                >
                                    Filtered by: {activeFilters.join(', ')}
                                </Typography>
                            )}
                        </div>
                        <div style={{ flex: 1 }} />
                        <Button
                            onClick={() => {
                                const buttons = this.renderUserInfoButtons();
                                const currentIndex = buttons.findIndex((btn) => btn.props.children === currentChartTitle);
                                const prevIndex = currentIndex > 0 ? currentIndex - 1 : buttons.length - 1;
                                const prevButton = buttons[prevIndex];
                                prevButton.props.onClick();
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            onClick={() => {
                                const buttons = this.renderUserInfoButtons();
                                const currentIndex = buttons.findIndex((btn) => btn.props.children === currentChartTitle);
                                const nextIndex = currentIndex < buttons.length - 1 ? currentIndex + 1 : 0;
                                const nextButton = buttons[nextIndex];
                                nextButton.props.onClick();
                            }}
                        >
                            Next
                        </Button>
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            flex: 1,
                            minHeight: 0,
                            position: 'relative'
                        }}
                    >
                        <internals.DialogContentWrapper>
                            <internals.ButtonContainer>
                                <Button
                                    size="small"
                                    onClick={this.toggleExpandOthers}
                                    sx={{
                                        backgroundColor: 'white',
                                        '&:hover': {
                                            backgroundColor: '#f5f5f5'
                                        }
                                    }}
                                >
                                    {expandOthers ? 'Group Others' : 'Show Others'}
                                </Button>
                            </internals.ButtonContainer>
                            <div style={{ display: 'flex', height: '95%' }}>
                                {/* Custom Legend */}
                                <internals.LegendContainer>
                                    {labels.map((label, index) => {
                                        const isOther = !pieData.labels.includes(label);
                                        return (
                                            <div
                                                key={label}
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: '8px',
                                                    fontSize: '12px',
                                                    alignItems: 'flex-start'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: isOther ? 'grey' : colors[index % colors.length],
                                                        marginRight: '8px',
                                                        marginTop: '4px',
                                                        flexShrink: 0
                                                    }}
                                                />
                                                <internals.LegendLabel>
                                                    {label}
                                                </internals.LegendLabel>
                                            </div>
                                        );
                                    })}
                                </internals.LegendContainer>
                                {/* Chart */}
                                <div style={{ flex: 1, display: 'flex', alignItems: 'center', maxHeight: '450px' }}>
                                    <Pie
                                        key={chartKey}
                                        ref={this.chartRef}
                                        data={{
                                            labels: pieData.labels,
                                            outLabels: pieData.outLabels,
                                            datasets: pieData.datasets
                                        }}
                                        options={chartOptionsWithLabels}
                                    />
                                </div>
                            </div>
                        </internals.DialogContentWrapper>
                    </DialogContent>
                    <DialogActions sx={{ position: 'relative', zIndex: 1 }}>
                        <Button onClick={this.closeModal} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </internals.DialogContainer>
            </Dialog>
        );
    }

    _createWordcloudData = (words) => {
        if (!words || !Array.isArray(words)) {
            return {
                words: [],
                key: ''
            };
        }

        const processedWords = words.map((wordObj) => ({
            text: String(wordObj.word),
            value: parseInt(wordObj.count, 10)
        }));

        return {
            words: processedWords,
            key: `wordcloud-${processedWords.map((w) => `${w.text}-${w.value}`).join('-')}`
        };
    }

    _renderGroupsDialog = () => {
        const { groupsDialogOpen, selectedGroup } = this.state;
        const { messagesStats } = this.props;
        let groupsData = messagesStats?.byGroup || [];

        groupsData = [...groupsData].sort((a, b) => {
            if (a.conversationId === 'direct_messages') {
                return -1;
            }

            if (b.conversationId === 'direct_messages') {
                return 1;
            }

            return 0;
        });

        const { words: wordcloudData, key: wordcloudKey } = selectedGroup?.topWords
            ? this._createWordcloudData(selectedGroup.topWords)
            : { words: [], key: '' };

        return (
            <Dialog
                open={groupsDialogOpen}
                onClose={this.handleGroupsDialogClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    <div>Recent Chats</div>
                    <div style={{ flex: 1 }} />
                    <Button
                        onClick={() => {
                            const currentIndex = groupsData.findIndex((g) => g === selectedGroup);
                            const prevIndex = currentIndex > 0 ? currentIndex - 1 : groupsData.length - 1;
                            this.setState({ selectedGroup: groupsData[prevIndex] });
                        }}
                    >
                        Previous
                    </Button>
                    <Button
                        onClick={() => {
                            const currentIndex = groupsData.findIndex((g) => g === selectedGroup);
                            const nextIndex = currentIndex < groupsData.length - 1 ? currentIndex + 1 : 0;
                            this.setState({ selectedGroup: groupsData[nextIndex] });
                        }}
                    >
                        Next
                    </Button>
                </DialogTitle>
                <DialogContent sx={{ overflow: 'hidden' }}>
                    <div style={{ display: 'flex', height: '400px' }}>
                        <div style={{
                            flex: 1,
                            padding: '20px',
                            overflow: 'hidden',
                            position: 'relative'
                        }}>
                            {selectedGroup ? (
                                <>
                                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                                        {this._renderGroupName(selectedGroup)}
                                    </Typography>
                                    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                                        <Typography variant="body1" style={{ marginTop: '5px' }}>
                                            {selectedGroup.conversationId === 'direct_messages' ? `${selectedGroup.participantCount} Students` : `${selectedGroup.participantCount} Members`}
                                        </Typography>
                                        <Typography variant="body1">
                                            Last {selectedGroup.count} Messages
                                        </Typography>
                                    </div>
                                    {wordcloudData.length > 0 && (
                                        <div style={{ height: '230px' }}>
                                            <MemoizedWordcloud
                                                key={wordcloudKey}
                                                words={wordcloudData}
                                                options={this.wordcloudOptions}
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px' }}>
                                    Select a group to view details
                                </Typography>
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleGroupsDialogClose}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

    getButtonConfig = (key, data) => {
        const titleMap = {
            roles: 'Student Role',
            incomingClass: 'Incoming Class',
            graduatingClass: 'Graduating Class',
            fullTime: 'Student Status',
            gender: 'Gender',
            veteran: 'Veteran',
            transfer: 'Transfer',
            parent: 'Parent',
            online: 'Learning Mode',
            remote: 'Location Status',
            badges: 'Badge',
            majors: 'Major'
        };

        if (key === 'fullTime') {
            return {
                title: titleMap[key],
                data: [
                    { name: 'Full Time', count: data.find((x) => x.isFullTime)?.count || 0 },
                    { name: 'Part Time', count: data.find((x) => !x.isFullTime)?.count || 0 }
                ]
            };
        }

        return {
            title: titleMap[key] || key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
            data
        };
    }

    _renderAverageUserSection = () => {
        const { userStats } = this.props;
        const { showAverages } = this.state;

        const totalUsers = userStats?.averageUserStats?.averages?.totals || 0;

        const metrics = [
            {
                label: 'Connections Made',
                total: userStats?.averageUserStats?.averages?.connections?.toLocaleString() || '0',
                average: totalUsers ? (userStats?.averageUserStats?.averages?.connections / totalUsers * 2).toFixed(1).toLocaleString() : '0'
            },
            {
                label: 'Interests Added',
                total: userStats?.averageUserStats?.averages?.interests?.toLocaleString() || '0',
                average: totalUsers ? (userStats?.averageUserStats?.averages?.interests / totalUsers).toFixed(1).toLocaleString() : '0'
            },
            {
                label: 'Group Messages Sent',
                total: userStats?.averageUserStats?.averages?.groupMessages?.toLocaleString() || '0',
                average: totalUsers ? (userStats?.averageUserStats?.averages?.groupMessages / totalUsers).toFixed(1).toLocaleString() : '0'
            },
            {
                label: 'Direct Messages Sent',
                total: userStats?.averageUserStats?.averages?.directMessages?.toLocaleString() || '0',
                average: totalUsers ? (userStats?.averageUserStats?.averages?.directMessages / totalUsers).toFixed(1).toLocaleString() : '0'
            }
        ];

        return (
            <Grid container sx={{ px: 2 }}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
                    <Typography variant="h4" align="left">
                        {showAverages ? 'Average Activity' : 'Total Activity'}
                    </Typography>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => this.setState((prev) => ({ showAverages: !prev.showAverages }))}
                    >
                        Show {showAverages ? 'Totals' : 'Averages'}
                    </Button>
                </Grid>
                {metrics.map((metric, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <internals.StyledPaper>
                            <Typography
                                variant="h6"
                                style={{
                                    fontSize: '1rem',
                                    textAlign: 'center',
                                    height: '45px',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center'
                                }}
                            >
                                {metric.label}
                            </Typography>
                            <Typography
                                variant="h4"
                                style={{
                                    fontSize: '1.5rem',
                                    textAlign: 'center',
                                    marginTop: 'auto'
                                }}
                            >
                                {showAverages ? metric.average : metric.total}
                            </Typography>
                        </internals.StyledPaper>
                    </Grid>
                ))}
            </Grid>
        );
    }

    _handleFilterChange(filterType, event) {
        const value = event.target.value;

        switch (filterType) {
            case 'selectedIncomingClass':
                this.setState({ tempIncomingClass: value });
                break;
            case 'selectedGender':
                this.setState({ tempGender: value });
                break;
            case 'selectedTransfer':
                this.setState({ tempTransfer: value });
                break;
        }
    }

    applyFilters = () => {
        const { tempIncomingClass, tempGender, tempTransfer } = this.state;

        const newActiveFilters = [
            tempIncomingClass && `Class of ${tempIncomingClass}`,
            tempGender && `${tempGender.charAt(0).toUpperCase() + tempGender.slice(1)}`,
            tempTransfer && (tempTransfer === 'transfer' ? 'Transfer Students' : 'Non-Transfer Students')
        ].filter(Boolean);

        this.setState({
            selectedIncomingClass: tempIncomingClass,
            selectedGender: tempGender,
            selectedTransfer: tempTransfer,
            activeFilters: newActiveFilters
        }, () => {
            this._fetchStats();
        });
    }

    resetFilters = () => {
        this.setState({
            tempIncomingClass: '',
            tempGender: '',
            tempTransfer: '',
            selectedIncomingClass: '',
            selectedGender: '',
            selectedTransfer: '',
            activeFilters: []
        }, () => {
            this._fetchStats();
        });
    }

    renderFilters = () => {
        const { tempIncomingClass, tempGender, tempTransfer, loading } = this.state;
        const incomingClasses = (this.props.userStats?.incomingClass || [])
            .map((c) => c.type)
            .filter(Boolean)
            .sort((a, b) => b - a);

        return (
            <internals.FilterContainer>
                <internals.StyledFormControl>
                    <InputLabel>Incoming Class</InputLabel>
                    <Select
                        value={tempIncomingClass}
                        onChange={(e) => this.handleFilterChange('selectedIncomingClass', e)}
                        label="Incoming Class"
                        disabled={loading}
                    >
                        <MenuItem value="">All Classes</MenuItem>
                        {incomingClasses.map((year) => (
                            <MenuItem key={year} value={year}>
                                Class of {year}
                            </MenuItem>
                        ))}
                    </Select>
                </internals.StyledFormControl>

                <internals.StyledFormControl>
                    <InputLabel>Gender</InputLabel>
                    <Select
                        value={tempGender}
                        onChange={(e) => this.handleFilterChange('selectedGender', e)}
                        label="Gender"
                        disabled={loading}
                    >
                        <MenuItem value="">All Genders</MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                    </Select>
                </internals.StyledFormControl>

                <internals.StyledFormControl>
                    <InputLabel>Transfer Status</InputLabel>
                    <Select
                        value={tempTransfer}
                        onChange={(e) => this.handleFilterChange('selectedTransfer', e)}
                        label="Transfer Status"
                        disabled={loading}
                    >
                        <MenuItem value="">All Students</MenuItem>
                        <MenuItem value="transfer">Transfer Students</MenuItem>
                        <MenuItem value="non-transfer">Non-Transfer Students</MenuItem>
                    </Select>
                </internals.StyledFormControl>

                <Button
                    variant="contained"
                    onClick={this.applyFilters}
                    disabled={loading}
                    size="small"
                >
                    {loading ? 'Applying...' : 'Apply Filters'}
                </Button>

                <Button
                    variant="outlined"
                    onClick={this.resetFilters}
                    disabled={loading}
                    size="small"
                >
                    Reset Filters
                </Button>
            </internals.FilterContainer>
        );
    }

    render() {

        const { userStats = {}, messagesStats = {}, schools } = this.props;
        const { loading, activeFilters } = this.state;

        if (loading) {
            return <div style={{ marginTop: 100 }}><Loader /></div>;
        }

        const groupsData = messagesStats?.byGroup || [];

        const userGrowthData = {
            labels: Object.values(userStats?.growthStats?.userGrowth || {})
                .map((day) => new Date(day.date).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })),
            datasets: Object.entries(
                Object.values(userStats?.growthStats?.userGrowth || {}).reduce((acc, day) => {
                    Object.entries(day.roles).forEach(([role, count]) => {
                        acc[role] = true;
                    });
                    return acc;
                }, {})
            ).map(([roleName], index) => ({
                label: roleName,
                data: Object.values(userStats?.growthStats?.userGrowth || {})
                    .map((day) => day.roles[roleName] || 0),
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(54, 162, 235, 0.6)'
                ][index % 5],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(54, 162, 235, 1)'
                ][index % 5],
                borderWidth: 2,
                tension: 0.3,
                fill: true
            }))
        };

        const messageGrowthData = {
            labels: Object.values(userStats?.growthStats?.messageGrowth || {})
                .map((day) => new Date(day.date).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })),
            datasets: Object.entries(
                Object.values(userStats?.growthStats?.messageGrowth || {}).reduce((acc, day) => {
                    Object.entries(day.roles).forEach(([role, count]) => {
                        acc[role] = true;
                    });
                    return acc;
                }, {})
            ).map(([roleName], index) => ({
                label: roleName,
                data: Object.values(userStats?.growthStats?.messageGrowth || {})
                    .map((day) => day.roles[roleName] || 0),
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(54, 162, 235, 0.6)'
                ][index % 5],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(54, 162, 235, 1)'
                ][index % 5],
                borderWidth: 2,
                tension: 0.3,
                fill: true
            }))
        };

        const connectionGrowthData = {
            labels: Object.values(userStats?.growthStats?.connectionGrowth || {})
                .map((day) => new Date(day.date).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })),
            datasets: Object.entries(
                Object.values(userStats?.growthStats?.connectionGrowth || {}).reduce((acc, day) => {
                    Object.entries(day.roles).forEach(([role, count]) => {
                        acc[role] = true;
                    });
                    return acc;
                }, {})
            ).map(([roleName], index) => ({
                label: roleName,
                data: Object.values(userStats?.growthStats?.connectionGrowth || {})
                    .map((day) => day.roles[roleName] || 0),
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(54, 162, 235, 0.6)'
                ][index % 5],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(54, 162, 235, 1)'
                ][index % 5],
                borderWidth: 2,
                tension: 0.3,
                fill: true
            }))
        };

        const lineOptions = {
            responsive: true,
            layout: {
                padding: {
                    top: 20
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: (value) => Number.isInteger(value) ? value.toLocaleString() : null
                    }
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top'
                },
                title: {
                    display: true,
                    text: 'User Growth by Role'
                }
            }
        };

        return (
            <internals.DashboardWrapper>
                <div style={{ width: '100%', display: 'flex' }}>
                    {/* Left Menu */}
                    <div style={{ width: '240px', marginRight: '1em', marginLeft: '1em' }}>
                        {/* Groups Data Section */}
                        <Typography variant="h4" style={{ marginTop: '2em' }} sx={{ pl: 2 }}>
                            Recent chats
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary" style={{ marginLeft: '1em' }}>
                            (Last 500 messages)
                        </Typography>
                        <List>
                            {/* Direct Messages Section */}
                            {groupsData
                                .filter((group) => group.conversationId === 'direct_messages')
                                .map((group, index) => (
                                    <ListItem
                                        disablePadding={true}
                                        key={index}
                                        onClick={() => {
                                            this.setState({
                                                groupsDialogOpen: true,
                                                selectedGroup: group
                                            });
                                        }}
                                        sx={{
                                            cursor: 'pointer',
                                            borderBottom: '1px solid #e0e0e0',
                                            borderTop: '1px solid #e0e0e0',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                            },
                                            padding: '6px 12px'
                                        }}
                                    >
                                        <internals.StyledListItemText
                                            primary={this._renderGroupName(group)}
                                            secondary={`${group.participantCount} students`}
                                        />
                                    </ListItem>
                                ))}

                            {/* Groups Title */}
                            <internals.GroupTitle>
                                Groups
                            </internals.GroupTitle>

                            {/* Render all groups */}
                            {groupsData
                                .filter((group) => group.conversationId !== 'direct_messages')
                                .map((group, index) => (
                                    <ListItem
                                        disablePadding={true}
                                        key={index}
                                        onClick={() => {
                                            this.setState({
                                                groupsDialogOpen: true,
                                                selectedGroup: group
                                            });
                                        }}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                            },
                                            borderBottom: '1px solid #e0e0e0',
                                            padding: '6px 12px'
                                        }}
                                    >
                                        <internals.StyledListItemText
                                            primary={this._renderGroupName(group)}
                                            secondary={`${group.participantCount} members`}
                                            $hasIcon={!!(group.emojiSymbol || group.conversationId === 'direct_messages')}
                                        />
                                    </ListItem>
                                ))}
                        </List>
                        {this.renderGroupsDialog()}
                    </div>

                    {/* Main Content */}
                    <internals.MainContentWrapper>
                        <internals.ContentLayout>
                            <internals.ContentSection>
                                <internals.SchoolHeader>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
                                            <Typography variant="h5">
                                                {this.props.currentUser?.email?.includes('nearpeer.net') ? (
                                                    <FormControl size="large" sx={{ minWidth: 200 }}>
                                                        <InputLabel>{'Select Dashboard'}</InputLabel>
                                                        <Select
                                                            value={this.state.selectedSchoolId}
                                                            onChange={this.handleSchoolChange}
                                                            label='Select Dashboard'
                                                        >
                                                            {schools.map((school) => (
                                                                <MenuItem key={school.id} value={school.id}>
                                                                    {school.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                ) : (
                                                    `${schools.find((school) => school.id === this.props.currentUser?.schoolId)?.name || ''} Dashboard (Pre-Release)`
                                                )}
                                            </Typography>
                                        </div>
                                        <Typography variant="h4" color="textSecondary">
                                            {new Date().toLocaleDateString('en-US', {
                                                weekday: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            {this.state.filteredUserCount.toLocaleString()} out of {this.state.totalUserCount.toLocaleString()} students
                                        </Typography>
                                    </div>
                                    {this.props.userStats?.schoolSvg?.svg && (
                                        <internals.SchoolImage
                                            src={this.props.userStats?.schoolSvg?.svg.includes('svg') ? this.getSvgImageData(this.props.userStats?.schoolSvg?.svg) : getOriginalImageUrl(this.props.userStats?.schoolSvg?.svg)}
                                            alt="School logo"
                                        />
                                    )}
                                </internals.SchoolHeader>

                                {this.renderFilters()}

                                {this._renderAverageUserSection()}

                                <internals.AnalyticsGrid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4">
                                            Analytics
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: '0.5em' }}>
                                            Last 12 months{(this.state.selectedIncomingClass || this.state.selectedGender || this.state.selectedTransfer) ? ' (Not Filtered)' : ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Tabs
                                            value={this.state.activeTab}
                                            onChange={(event, newValue) => {
                                                // eslint-disable-next-line handle-callback-err
                                                this.setState({ activeTab: newValue });
                                            }}
                                            sx={{
                                                mb: 2,
                                                '& .MuiTab-root': {
                                                    color: 'rgba(0, 0, 0, 0.87)',
                                                    fontWeight: 500
                                                },
                                                '& .Mui-selected': {
                                                    color: 'purple !important'
                                                },
                                                '& .MuiTabs-indicator': {
                                                    backgroundColor: 'purple'
                                                }
                                            }}
                                        >
                                            <Tab label="Hometowns" value={0} />
                                            <Tab label="User Growth" value={1} />
                                            <Tab label="Message Growth" value={2} />
                                            <Tab label="Connection Growth" value={3} />
                                        </Tabs>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <internals.ChartContainer $isMap={this.state.activeTab === 0}>
                                            {this.state.activeTab === 0 ? (
                                                <internals.MapWrapper>
                                                    <MapContainer
                                                        center={[39.8283, -98.5795]}
                                                        zoom={4}
                                                        style={{ width: '100%', height: '400px' }}
                                                        zoomControl={true}
                                                        dragging={true}
                                                        touchZoom={true}
                                                        doubleClickZoom={true}
                                                        scrollWheelZoom={true}
                                                        boxZoom={true}
                                                        keyboard={true}
                                                        minZoom={3}
                                                        maxZoom={12}
                                                    >
                                                        <TileLayer
                                                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                                        />
                                                        {groupCoordinates(userStats?.growthStats?.hometownCoordinates || []).map((location, index, array) => {
                                                            const allCounts = array.map((loc) => loc.count);
                                                            const radius = getMarkerRadius(location.count, allCounts);
                                                            return (
                                                                <CircleMarker
                                                                    key={index}
                                                                    center={[location.lat, location.lng]}
                                                                    radius={radius}
                                                                    fillColor="#4a90e2"
                                                                    color="#2171ce"
                                                                    weight={2}
                                                                    opacity={1}
                                                                    fillOpacity={0.6}
                                                                    eventHandlers={{
                                                                        mouseover: (e) => {
                                                                            const layer = e.target;
                                                                            layer.setStyle({
                                                                                fillOpacity: 0.9,
                                                                                weight: 3,
                                                                                color: '#1e5799'
                                                                            });
                                                                        },
                                                                        mouseout: (e) => {
                                                                            const layer = e.target;
                                                                            layer.setStyle({
                                                                                fillOpacity: 0.6,
                                                                                weight: 2,
                                                                                color: '#2171ce'
                                                                            });
                                                                        }
                                                                    }}
                                                                >
                                                                    <Popup>
                                                                        <div style={{ textAlign: 'center' }}>
                                                                            <strong>{location.count}</strong> {location.count === 1 ? 'student' : 'students'} from this area
                                                                            <br />
                                                                        </div>
                                                                    </Popup>
                                                                </CircleMarker>
                                                            );
                                                        })}
                                                    </MapContainer>
                                                </internals.MapWrapper>
                                            ) : this.state.activeTab === 1 ? (
                                                <Line
                                                    data={userGrowthData}
                                                    options={lineOptions}
                                                />
                                            ) : this.state.activeTab === 2 ? (
                                                <Line
                                                    data={messageGrowthData}
                                                    options={lineOptions}
                                                />
                                            ) : (
                                                <Line
                                                    data={connectionGrowthData}
                                                    options={lineOptions}
                                                />
                                            )}
                                        </internals.ChartContainer>
                                    </Grid>
                                </internals.AnalyticsGrid>

                                <Grid item xs={12} style={{ marginTop: '2em', marginLeft: '1em' }}>
                                    <Typography variant="h4">
                                        Interests & Passions
                                    </Typography>
                                    {activeFilters?.length > 0 && (
                                        <Typography
                                            variant="subtitle1"
                                            color="text.secondary"
                                            sx={{ mt: 0.5, mb: 1 }}
                                        >
                                            Filtered by: {activeFilters.join(', ')}
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid container spacing={3} component={internals.InterestsSection}>
                                    <Grid item xs={12} md={4}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', height: '40px' }}>
                                            <Typography variant="h6" align="left">
                                                Top Interests
                                            </Typography>
                                        </div>
                                        <internals.InterestsList>
                                            {(userStats?.interests || []).map((interest, index) => (
                                                <ListItem key={index}>
                                                    <internals.IconWrapper>
                                                        {interest.svg && (
                                                            <internals.StyledIcon
                                                                alt={`${interest.name} icon`}
                                                                src={this.getSvgImageData(interest.svg)}
                                                            />
                                                        )}
                                                    </internals.IconWrapper>
                                                    <internals.StyledListItemText
                                                        primary={<internals.InterestText>{index + 1 + '. ' + interest.name}</internals.InterestText>}
                                                    />
                                                </ListItem>
                                            ))}
                                        </internals.InterestsList>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', height: '40px' }}>
                                            <Typography variant="h6" align="left">
                                                Top Passions
                                            </Typography>
                                            <Tooltip title="Each user can optionally designate up to 5 of their Interests as Passions">
                                                <IconButton size="small">
                                                    <HelpOutlineIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <internals.InterestsList>
                                            {(userStats?.passionInterests || []).map((interest, index) => (
                                                <ListItem key={index}>
                                                    <internals.IconWrapper>
                                                        {interest.svg && (
                                                            <internals.StyledIcon
                                                                alt={`${interest.name} icon`}
                                                                src={this.getSvgImageData(interest.svg)}
                                                            />
                                                        )}
                                                    </internals.IconWrapper>
                                                    <internals.StyledListItemText
                                                        primary={<internals.InterestText>{index + 1 + '. ' + interest.name}</internals.InterestText>}
                                                    />
                                                </ListItem>
                                            ))}
                                        </internals.InterestsList>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', height: '40px' }}>
                                            <Typography variant="h6" align="left">
                                                Top Custom Interests
                                            </Typography>
                                        </div>
                                        <internals.InterestsList>
                                            {(userStats?.customInterests || []).map((interest, index) => (
                                                <ListItem key={index}>
                                                    <internals.IconWrapper>
                                                        {interest.svg && (
                                                            <internals.StyledIcon
                                                                alt={`${interest.name} icon`}
                                                                src={this.getSvgImageData(interest.svg)}
                                                            />
                                                        )}
                                                    </internals.IconWrapper>
                                                    <internals.StyledListItemText
                                                        primary={<internals.InterestText>{index + 1 + '. ' + interest.name}</internals.InterestText>}
                                                    />
                                                </ListItem>
                                            ))}
                                        </internals.InterestsList>
                                    </Grid>
                                </Grid>
                            </internals.ContentSection>

                            {/* Right side (User Info) */}
                            <internals.UserAnalysisWrapper>
                                <Button
                                    component={Link}
                                    to={{
                                        pathname: '/app/bug-report',
                                        state: {
                                            isAdminContact: true
                                        }
                                    }}
                                    variant="outlined"
                                    startIcon={<HelpOutlineIcon />}
                                    sx={{
                                        mt: '2em',
                                        textTransform: 'none'
                                    }}
                                >
                                    Contact Help Desk
                                </Button>
                                <internals.UserAnalysisTitle variant="h4">
                                    User Profile Analysis
                                </internals.UserAnalysisTitle>
                                {(this.state.selectedIncomingClass || this.state.selectedGender || this.state.selectedTransfer) && (
                                    <Typography
                                        variant="subtitle1"
                                        color="textSecondary"
                                        sx={{ mb: 2 }}
                                    >
                                        Filtered by: {[
                                            this.state.selectedIncomingClass && `Class of ${this.state.selectedIncomingClass}`,
                                            this.state.selectedGender && `${this.state.selectedGender.charAt(0).toUpperCase() + this.state.selectedGender.slice(1)}`,
                                            this.state.selectedTransfer && (this.state.selectedTransfer === 'transfer' ? 'Transfer Students' : 'Non-Transfer Students')
                                        ].filter(Boolean).join(', ')}
                                    </Typography>
                                )}
                                <internals.UserAnalysisButtons>
                                    {this.renderUserInfoButtons()}
                                </internals.UserAnalysisButtons>
                                {this.renderUserInfoModal()}
                            </internals.UserAnalysisWrapper>
                        </internals.ContentLayout>
                    </internals.MainContentWrapper>
                </div>
            </internals.DashboardWrapper>
        );
    }

    getSvgImageData = (svg) => {
        if (!svg) {
            return null;
        }

        const buff = Buffer.from(svg);
        const base64data = buff.toString('base64');
        return `data:image/svg+xml;base64,${base64data}`;
    }

    _renderGroupName = (group) => {
        if (!group) {
            return '';
        }

        return (
            <span>
                {group.conversationId === 'direct_messages' ? (
                    <ChatIcon sx={{ fontSize: '1em', marginRight: '5px' }} />
                ) : group.emojiSymbol ? (
                    <span style={{ fontSize: '1em', marginRight: '5px' }}>{group.emojiSymbol}</span>
                ) : null}
                {group.className}
            </span>
        );
    }
}

module.exports = AdminDashboard;
